<template>
  <custom-dialog :dialog="passwordDialog" :env-selected="envSelected" :do-action="resetUserPassword">
    <v-row v-if="passwordDialog.data">
      <v-col :cols="(passwordDialog.pwdHint) ? 6 : 12">
        <v-skeleton-loader
            v-if="passwordDialog.loading"
            type="article, actions"
        ></v-skeleton-loader>
        <template v-else>
          <v-card>
            <v-card-title class="pl-5 ml-0 text-left">
              <p class="text-muted">Changing Password for User '{{ passwordDialog.data.firstName }}
                {{ passwordDialog.data.lastName }}'
              </p>
            </v-card-title>

            <v-card-text>
              <v-text-field
                  type="password"
                  color="secondary"
                  label="Password..."
                  prepend-icon="mdi-lock-outline"
                  v-model="passwordDialog.data.password"
              />
              <v-text-field
                  type="password"
                  color="secondary"
                  label="Confirm Password..."
                  prepend-icon="mdi-lock-outline"
                  v-model="passwordDialog.data.confirmation"
              />
            </v-card-text>
          </v-card>
        </template>
      </v-col>
      <v-col cols="6" v-if="passwordDialog.pwdHint">
        <v-alert
            class="pa-1 pl-5"
            type="info"
            outlined
            border="left">
          The Password requires at least one digit.
        </v-alert>
        <v-alert
            class="pa-1 pl-5"
            type="info"
            outlined
            border="left">
          The Password requires at least one lowercase letter.
        </v-alert>
        <v-alert
            class="pa-1 pl-5"
            type="info"
            outlined
            border="left">
          The Password requires at least one uppercase letter.
        </v-alert>
        <v-alert
            class="pa-1 pl-5"
            type="info"
            outlined
            border="left">
          The Password requires at least one special character.
        </v-alert>
        <v-alert
            class="pa-1 pl-5"
            type="info"
            outlined
            border="left">
          The Password should be between 10 and 32 characters.
        </v-alert>
      </v-col>
    </v-row>
  </custom-dialog>
</template>
<script>

import globalMixins from "@/mixins/globalMixins";
import CustomDialog from "@/views/internal/components/custom/dialog";

export default {
  name: 'ChangeUserPassword',

  data(){
    return {
      passwordDialog: {
        title: 'Reset User Password',
        trigger: false,
        loading: false,
        pwdHint: false,
        data: null,
        actions: [
          {
            method: 'resetUserPassword',
            icon: 'content-save',
            color: 'success',
            label: 'Reset'
          }
        ]
      }
    }
  },

  props: {
    envSelected: {
      type: Object
    }
  },

  components: {
    CustomDialog
  },

  mixins: [ globalMixins ],

  methods: {

    changeUserPassword: function ( user ) {
      const _self = this;
      if ( confirm( `Are you sure you want to change the password for user: ${ user.username }?` ) ) {
        _self.passwordDialog.trigger = true;
        _self.passwordDialog.loading = true;
        _self.passwordDialog.data = JSON.parse(JSON.stringify(user));
        setTimeout(() => {
          _self.passwordDialog.loading = false;
        }, 500);
      }
    },

    resetUserPassword: function(data){
      const _self = this;
      this.passwordDialog.width = 500;
      this.passwordDialog.pwdHint = false;
      this.passwordDialog.errors = [];
      if (!this.validPassword(this.passwordDialog.data.password)) {
        this.passwordDialog.errors.push('The password should be at least 10 characters long and contain 1 uppercase letter, 1 lowercase letter, 1 special character and 1 digit.');
        this.passwordDialog.pwdHint = true;
        this.passwordDialog.width = 800;
        return false;
      }

      if (this.passwordDialog.data.password !== this.passwordDialog.data.confirmation) {
        this.passwordDialog.errors.push('The provided password and confirmation don\'t match.');
        return false;
      }

      doResetPassword();

      function doResetPassword() {

        const apiOptions = {
          'url': `/consoleapi/organization/users/${_self.passwordDialog.data.id}`,
          'method': 'patch',
          "params": {
            "password": _self.passwordDialog.data.password
          }
        };
        // close the dialog
        _self.passwordDialog.trigger = false;
        _self.getSendData( apiOptions ).then( () => {
          _self.pushMessage( {
                               type: 'success',
                               title: `Reset User Password`,
                               text: `The password of user ${ _self.passwordDialog.data.firstName } ${ _self.passwordDialog.data.lastName } has been changed.`
                             } );
        } );
      }
    }
  }
};
</script>