/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */

import globalMixins from "@/mixins/globalMixins";

export default {
	name: 'usersMixins',
	
	mixins: [globalMixins],
	
	methods: {
		
		validateUserRecord: function (user, checkPwd) {
			let errors = [];
			if (!user.firstName || user.firstName.trim() === '') {
				errors.push("The field First Name is required!");
			}
			
			if (!user.lastName || user.lastName.trim() === '') {
				errors.push("The field Last Name is required!");
			}
			
			if (!user.email || user.email.trim() === '') {
				errors.push("The field Email is required!");
			}
			
			if (!this.validEmail(user.email)) {
				errors.push("The provided email address is invalid!");
			}
			
			if (!user.username || user.username.trim() === '') {
				errors.push("The field Username is required!");
			}
			
			if (!user.account || user.account.trim() === '') {
				errors.push("The filed Company is required!");
			}
			
			if (checkPwd) {
				
				if (!user.password || user.password.trim() === '' || !this.validPassword(user.password)) {
					errors.push("Invalid or no password Provided!");
				}
				
				if (user.password !== user.confirmation) {
					errors.push("The password and its confirmation do not match!");
				}
			}
			
			if (errors.length > 0) {
				errors.forEach((oneError) => {
					this.pushMessage({
						type: 'error',
						title: 'Validation Error',
						text: oneError
					});
				});
				this.scrollToTop();
				return false;
			}
			
			return true;
		},
		
		cleanUpUserOrganization: function (userOriginal, organizationOriginal) {
			const _self = this;
			let user = this._lodash.cloneDeep(userOriginal);
			let organization = this._lodash.cloneDeep(organizationOriginal);
			
			function fixSecurity(user, organization) {
				//normalize the throttling strategies
				if (user.security.throttling && user.security.throttling.strategies) {
					user.security.throttling.strategies.forEach((oneStrategy) => {
						user.security.throttling[oneStrategy.name] = oneStrategy;
						delete user.security.throttling[oneStrategy.name].name
					});
					delete user.security.throttling.strategies;
				}
				
				if (user.security.throttling.privateAPIStrategy) {
					if (!user.security.throttling[user.security.throttling.privateAPIStrategy]) {
						delete user.security.throttling.privateAPIStrategy;
					}
				}
				
				if (user.security.throttling.publicAPIStrategy) {
					if (!user.security.throttling[user.security.throttling.publicAPIStrategy]) {
						delete user.security.throttling.publicAPIStrategy;
					}
				}
				
				if (Object.keys(user.security.throttling).length === 0) {
					delete user.security.throttling;
				}
				
				if (Object.keys(user.security).length !== 0) {
					if (_self._lodash.isEqual(user.security, organization.security)) {
						user.security = {};
					}
				}
			}
			
			function fixGroups(user, organization) {
				let userGroups = [];
				for (let i = 0; i < user.groups.length; i++) {
					if (typeof user.groups[i] === 'object' && Object.hasOwnProperty.call(user.groups[i], 'code')) {
						userGroups.push(user.groups[i].code);
					} else {
						userGroups.push(user.groups[i]);
					}
				}
				
				let orgGroups = [];
				for (let i = 0; i < organization.groups.length; i++) {
					let newG = organization.groups[i];
					orgGroups.push(newG);
				}
				
				if (_self._lodash.isEqual(userGroups, orgGroups)) {
					user.groups = [];
				} else {
					let diff = _self._lodash.differenceWith(userGroups, orgGroups, _self._lodash.isEqual);
					user.groups = diff;
				}
			}
			
			function fixTenants(user, organization) {
				let userTenants = [];
				let tCodes = [];
				for (let i = 0; i < user.tenants.length; i++) {
					let newT = {
						id: user.tenants[i].id,
						code: user.tenants[i].code
					};
					tCodes.push(user.tenants[i].code);
					userTenants.push(newT);
				}
				
				for (let key in user.config) {
					if (!tCodes.includes(key)) {
						delete user.config[key];
					}
				}
				
				let orgTenants = [];
				for (let i = 0; i < organization.tenants.length; i++) {
					let newT = {
						"id": organization.tenants[i].id,
						"code": organization.tenants[i].code
					};
					orgTenants.push(newT);
				}
				
				if (_self._lodash.isEqual(userTenants, orgTenants)) {
					user.tenants = [];
				} else {
					let diff = _self._lodash.differenceWith(userTenants, orgTenants, _self._lodash.isEqual);
					user.tenants = diff;
				}
			}
			
			//check the security
			fixSecurity(user, organization);
			
			//check the groups
			fixGroups(user, organization);
			
			//check the tenants
			fixTenants(user, organization);
			
			if (user.id) {
				delete user.id;
				delete user.ts;
				delete user.author;
				delete user.updated;
				delete user.created;
			}
			
			return user;
		},
		
		validateAccountRecord: function (account) {
			let errors = [];
			if (!account.name || account.name.trim() === '') {
				errors.push("The field Organization Name is required!");
			}
			
			if (!account.groups || account.groups.length === 0) {
				errors.push("Select which groups this organization belongs to!");
			}
			
			if (errors.length > 0) {
				errors.forEach((oneError) => {
					this.pushMessage({
						type: 'error',
						title: 'Validation Error',
						text: oneError
					});
				});
				this.scrollToTop();
				return false;
			}
			
			return true;
		},
		
		cleanUpAccount: function (account) {
			let tenants = [];
			let tCodes = [];
			account.tenants.forEach((oneT) => {
				tenants.push({
					id: oneT.id,
					code: oneT.code
				});
				tCodes.push(oneT.code);
			});
			account.tenants = tenants;

			for (let key in account.config) {
				if (tCodes.includes(key)) {
					if (account.config[key].specific && !account.config[key].common) {
						account.config[key].common = {};
					}
				} else {
					delete account.config[key];
				}
			}
			
			let groups = [];
			account.groups.forEach((oneGroup) => {
				if (typeof oneGroup === 'object' && Object.hasOwnProperty.call(oneGroup, 'code')) {
					groups.push(oneGroup.code);
				}
			});
			if (groups.length > 0) {
				account.groups = groups;
			}
			
			if (account.security && account.security.throttling) {
				if (account.security.throttling.strategies) {
					//normalize the throttling strategies
					account.security.throttling.strategies.forEach((oneStrategy) => {
						account.security.throttling[oneStrategy.name] = oneStrategy;
						delete account.security.throttling[oneStrategy.name].name
					});
					delete account.security.throttling.strategies;
				}
				
				if (account.security.throttling.privateAPIStrategy) {
					if (!account.security.throttling[account.security.throttling.privateAPIStrategy]) {
						delete account.security.throttling.privateAPIStrategy;
					}
				}
				
				if (account.security.throttling.publicAPIStrategy) {
					if (!account.security.throttling[account.security.throttling.publicAPIStrategy]) {
						delete account.security.throttling.publicAPIStrategy;
					}
				}
				if (Object.keys(account.security.throttling).length === 0) {
					delete account.security.throttling;
				}
			}
			if (account.id) {
				delete account.id;
				delete account.ts;
				delete account.author;
				delete account.created;
			}
			
			return account;
		}
	}
};