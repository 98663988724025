<template>
  <v-container
      id="extended-tables"
      fluid
      tag="section"
  >
    <v-row no-gutters>
      <v-col cols="12" md="12">
        <base-material-card
            color="success"
            icon="mdi-account-multiple"
            title="Users"
        >
          <v-icon class="section-helper" @click="openHelpLink('users')">mdi-help-circle</v-icon>
          <v-row no-gutters>
            <v-col cols="12" md="12">

              <data-grid
                  :envSelected="envSelected"
                  :grid="grid"
                  @doAction="gridAction"
                  @paginate="listUsers"
              >
                <template v-slot:column-firstName="{ item }">
                  <div class="font-weight-medium">{{ item.firstName }} {{ item.lastName }}</div>
                  <span class="font-weight-light mt-1 font-italic grey--text">
                    ({{ item.username }})</span>
                </template>

                <template v-slot:column-email="{ item }">
                  <a class="blue--text" :href="`mailto:${item.email}`">{{ item.email }}</a>
                </template>

                <template v-slot:column-groups="{ item }">
                  <span class="font-weight-medium" v-for="(oneGroup, index) in item.groups" :key="index">
                    <v-hover
                        v-slot:default="{ hover }"
                        open-delay="50"
                    >
                      <v-chip
                          @click="goToGroup(oneGroup)"
                          class="ma-2"
                          :color="hover? 'primary white--text' : ''"
                      >
                      <v-avatar left>
                        <v-icon>mdi-account-group</v-icon>
                      </v-avatar>
                      {{ oneGroup }}
                        <help-circle :context="help.groups"></help-circle>
                    </v-chip>
                    </v-hover>
                  </span>
                </template>

                <template v-slot:column-status="{ item }">
                  <v-chip small label :color="item.status === 'active' ? 'success' : 'warning'">
                    {{ item.status }}
                  </v-chip>
                </template>

                <template v-slot:column-expanded="{ headers, item }">
                  <td :colspan="headers.length">
                    <div class="ma-5" v-if="grid.fieldAccess.tenants">
                      <template v-if="item.tenants && item.tenants.length > 0">
                        <h4>Applications</h4>
                        <span class="font-weight-medium" v-for="(oneTenant, index) in item.tenants" :key="index">
                    <v-hover
                        v-slot:default="{ hover }"
                        open-delay="50"
                    >
                      <v-chip
                          class="mt-2 mb-2 mr-2"
                          label
                          :color="hover ? 'primary white--text': 'info'"
                          @click="goToTenant(oneTenant)"
                      >
                        <v-avatar left>
                          <v-icon>mdi-apps</v-icon>
                        </v-avatar>
                        {{ oneTenant.code }}
                        <help-circle :context="help.tenants"></help-circle>
                      </v-chip>
                    </v-hover>
                </span>
                      </template>
                      <template v-else>
                        <p class="font-italic font-weight-light">
                          No Applications linked to this user.</p>
                      </template>
                    </div>
                  </td>
                </template>

              </data-grid>

              <change-user-password ref="changeUserPassword" :env-selected="envSelected"></change-user-password>
              <change-mfa ref="changeMfa"
                          @refreshList="refreshList"
                          :env-selected="envSelected"></change-mfa>

            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import ChangeUserPassword from "./components/changeUserPassword";
import ChangeMfa from "./components/changeMfa";
import usersMixins from "./components/mixins";
import fieldsMixins from "./components/fieldAccess";
import globalMixins from "../../../../mixins/globalMixins";
import dataGrid from "@/views/internal/components/custom/dataGrid";
import HelpCircle from "@/views/internal/components/custom/helpCircle";

const data = {
  help: {
    groups: {
      right: true,
      text: `User Security Group`,
      link: 'groups'
    },
    tenants: {
      bottom: true,
      text: `Accessible Multitenant Applications`,
      link: 'tenants'
    }
  },
  grid: {
    fieldAccess: {},
    //mandatory
    headers: [
      {
        text: '',
        value: 'data-table-expand'
      },
      {
        text: 'Name',
        value: 'firstName',
      },
      {
        text: 'Email',
        value: 'email',
      },
      {
        text: 'Group(s)',
        value: 'groups',
      },
      {
        text: 'Status',
        value: 'status',
        width: '10%'
      },
      {
        align: 'right',
        text: 'Actions',
        value: 'actions',
        width: '26%'
      }
    ],
    items: [],
    selected: [],
    //optional
    loading: true,
    //optional
    columns: [
      {
        name: 'item.firstName'
      },
      {
        name: 'item.email'
      },
      {
        name: 'item.groups'
      },
      {
        name: 'item.status'
      },
      {
        name: 'item.expanded'
      }
    ],
    //optional
    search: {
      keyword: ''
    },
    //optional
    itemID: 'id',
    //optional
    expand: {
      single: false,
      items: []
    },
    //optional
    multi: [
      {
        icon: 'delete',
        label: 'Delete',
        color: 'error',
        acl: {
          method: 'delete',
          route: `/organization/users/:id`
        },
        method: 'deleteUser',
        //optional
        confirm: `Are you sure you want to delete these User(s)?`
      }
    ],
    //optional
    sortingList: ['username', 'status'],
    sort: ['username', 1, 'status', 1],
    //optional
    headActions: [
      {
        icon: 'refresh',
        label: '',
        color: 'purple',
        acl: {method: 'get', route: `/organization/users`},
        method: 'listUsers'
      },
      {
        icon: 'plus',
        label: 'create',
        color: 'success',
        acl: {method: 'put', route: `/organization/users`},
        method: 'addUser'
      }
    ],
    //optional
    rowActions: [
      {
        method: 'approveUser',
        color: 'info',
        icon: 'mdi-check',
        condition: {
          field: 'status',
          value: 'verified'
        },
        tooltip: "Approve User"
      },
      {
        method: 'enableMfa',
        color: 'blue',
        icon: 'mdi-two-factor-authentication',
        // condition: {
        //   field: 'mfa',
        //   value: false
        // },
        allow: {
          method: 'patch',
          route: '/organization/users/:id'
        },
        tooltip: "Update MFA"
      },
      {
        method: 'enableDisableUser',
        color: 'error',
        icon: 'mdi-lightbulb-off',
        alternate: {
          key: 'status',
          color: 'success',
          icon: 'mdi-lightbulb-on'
        },
        allow: {
          method: 'patch',
          route: '/organization/users/:id'
        },
        tooltip: "Enable / Disable User"
      },
      {
        method: 'resetPassword',
        color: 'secondary',
        icon: 'mdi-lock',
        allow: {
          method: 'patch',
          route: '/organization/users/:id'
        },
        tooltip: "Reset Password"
      },
      {
        method: 'editUser',
        color: 'success',
        icon: 'mdi-pencil',
        allow: {
          method: 'patch',
          route: '/organization/users/:id'
        },
        tooltip: "Edit"
      },
      {
        method: 'deleteUser',
        color: 'error',
        icon: 'mdi-delete',
        allow: {
          method: 'delete',
          route: '/organization/users/:id'
        },
        tooltip: "Delete"
      },
    ]
  }
};

const methods = {

  async listUsers(page, limit, sort) {
    this.grid.loading = true;
    this.grid.items = [];

    if (page !== undefined) {
      this.grid.page = page;
    }
    if (limit !== undefined) {
      this.grid.limit = limit;
    }
    if (sort !== undefined) {
      this.grid.sort = sort;
    }
    const users = await this.getSendData({
      noLoading: true,
      url: `/consoleapi/organization/users`,
      method: "get",
      params: {
        offset: (this.grid.page !== undefined) ? this.grid.page : 1,
        limit: (this.grid.limit !== undefined) ? this.grid.limit : 10,
        sort: this.grid.sort
      }
    });
    this.grid.items = users.items || [];
    this.grid.items.forEach(function (one) {
      one.mfa = false;
      if (one.security && one.security.mfa) {
        one.mfa = one.security.mfa;
      }
    });
    this.grid.total = users.total || 1;
    this.grid.perPage = this.grid.limit;
    this.grid.loading = false;
  },

  //grid action hook
  gridAction(action, item, extra) {
    if (item) {
      this[action](item, extra);
    } else {
      this[action](extra);
    }
  },

  approveUser(item) {
    const _self = this;
    if (confirm(`Are you sure you want to approve the user: ${item.username}`)) {
      doChange();
    }

    function doChange() {

      const apiOptions = {
        url: `/register/approve`,
        method: "post",
        headers: {
          env: 'portal'
        },
        params: {
          "username": item.username
        }
      };

      _self.getSendData(apiOptions)
          .then(() => {
            _self.pushMessage({
              type: 'success',
              title: `User Approved`,
              text: `This user has been approved.`
            });
            setTimeout(() => {
              _self.listUsers();
            }, 2000);
          });
    }
  },

  addUser() {
    this.goToPage({route: 'addUser'});
  },

  editUser(item) {
    this.goToPage({route: 'editUser', params: {id: item.id}});
  },

  deleteUser(item, multi) {
    const _self = this;
    if (multi || (!multi && confirm(`Are you sure you want to delete user: ${item.username}`))) {
      doDelete();
    }

    function doDelete() {
      const apiOptions = {
        url: `/consoleapi/organization/users/${item.id}`,
        method: "delete"
      };
      _self.getSendData(apiOptions).then(() => {
        _self.pushMessage({
          type: 'success',
          title: `User Deleted`,
          text: `This user has been deleted.`
        });
        setTimeout(() => {
          _self.listUsers();
        }, 2000);
      });
    }
  },

  refreshList() {
    setTimeout(() => {
      this.listUsers();
    }, 2000);
  },

  enableMfa(item) {
    if (Object.hasOwnProperty.call(this.$refs.changeMfa, 'changeMfaConfig')) {
      this.$refs.changeMfa.changeMfaConfig(item);
    }
  },

  enableDisableUser(item) {
    const _self = this;
    let status = (item.status === 'active') ? 'disabled' : 'active';
    if (confirm(`Are you sure you want to change the status of user: ${item.username} to ${status}`)) {
      doChangeStatus();
    }

    function doChangeStatus() {
      const apiOptions = {
        url: `/consoleapi/organization/users/${item.id}`,
        method: "patch",
        "params": {
          "status": status
        }
      };
      _self.getSendData(apiOptions).then(() => {
        _self.pushMessage({
          type: 'success',
          title: `User Status Updated`,
          text: `The status of this user has been updated.`
        });
        setTimeout(() => {
          _self.listUsers();
        }, 2000);
      });
    }
  },

  resetPassword(item) {
    if (Object.hasOwnProperty.call(this.$refs.changeUserPassword, 'changeUserPassword')) {
      this.$refs.changeUserPassword.changeUserPassword(item);
    }
  },

  goToGroup(oneGroup) {
    this.goToPage({route: 'editGroup', params: {code: oneGroup}});
  },

  goToTenant(oneTenant) {
    this.goToPage({route: 'editTenantApplication', params: {id: oneTenant.id}});
  },

};

export default {
  name: 'Users',
  methods: methods,
  data: () => (data),
  mixins: [globalMixins, usersMixins, fieldsMixins],
  props: {
    envSelected: {
      type: Object
    }
  },
  components: {
    HelpCircle,
    dataGrid,
    ChangeMfa,
    ChangeUserPassword
  },
  created() {
    this.grid.fieldAccess = this.userListAccess();
  },
}
</script>

<style scoped>
.routerLink {
  text-decoration: none;
}

</style>
